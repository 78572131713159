import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import Main from '../views/Main.vue'
import i18n from "@/i18n";

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'fr'
      next()
    }
  },
  {
    path: '/en',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'en'
      next()
    }
  },
  {
    path: '/ru',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'ru'
      next()
    }
  },
  {
    path: '/implant-dentaire',
    component: Main,
    children: [
      {
        path: '',
        name: 'implant-dentaire',
        component: () => import(/* webpackChunkName: "dentalimplant" */ '../views/dental-implant/DentalImplant'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-traditionnel',
        component: Main,
        children: [
          {
            path: '',
            name: 'implant-traditionnel',
            component: () => import(/* webpackChunkName: "traditionaldentalimplant" */ '../views/dental-implant/traditional-dental-implant/TraditionalDentalImplant'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'greffe-osseuse',
            component: Main,
            children: [
              {
                path: '',
                name: 'greffe-osseuse',
                component: () => import(/* webpackChunkName: "bonecraft" */ '../views/dental-implant/traditional-dental-implant/bone-craft/BoneCraft'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              },
              {
                path: 'risques',
                name: 'risques-trad',
                component: () => import(/* webpackChunkName: "bonecraftrisk" */ '../views/dental-implant/traditional-dental-implant/bone-craft/BoneCraftRisk'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              }
            ]
          },
          {
            path: 'risques-et-rejet',
            name: 'risques-et-rejet',
            component: () => import(/* webpackChunkName: "traditionalimplantrisk" */ '../views/dental-implant/traditional-dental-implant/risk/TraditionalImplantRisk'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'all-on-4',
            name: 'all-on-4',
            component: () => import(/* webpackChunkName: "allonfour" */ '../views/dental-implant/traditional-dental-implant/all-on-4/AllOnFour'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'operation',
            name: 'operation-trad',
            component: () => import(/* webpackChunkName: "traditionalimplantoperation" */'../views/dental-implant/traditional-dental-implant/operation/TraditionalImplantOperation'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          }
        ]
      },
      {
        path: 'implant-basal',
        component: Main,
        children: [
          {
            path: '',
            name: 'implant-basal',
            component: () => import(/* webpackChunkName: "basaldentalimplant" */'../views/dental-implant/basal-dental-implant/BasalDentalImplant'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'prothese-fixe',
            name: 'prothese-fixe',
            component: () => import(/* webpackChunkName: "prosthesis" */'../views/dental-implant/basal-dental-implant/prothesis/Prosthesis'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'operation',
            name: 'operation-basal',
            component: () => import(/* webpackChunkName: "basalimplantoperation" */'../views/dental-implant/basal-dental-implant/operation/BasalImplantOperation'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'risques',
            name: 'risques-basal',
            component: () => import(/* webpackChunkName: "basalimplantrisk" */'../views/dental-implant/basal-dental-implant/risk/BasalImplantRisk'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'comparatif',
            component: Main,
            children: [
              {
                path: '',
                name: 'comparatif',
                component: () => import(/* webpackChunkName: "comparative" */'../views/dental-implant/basal-dental-implant/comparative/Comparative'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              },
              {
                path: 'eviter-rejet',
                name: 'eviter-rejet',
                component: () => import(/* webpackChunkName: "reject" */'../views/dental-implant/basal-dental-implant/comparative/Reject'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              },
              {
                path: 'immediat',
                name: 'immediat',
                component: () => import(/* webpackChunkName: "immediate" */'../views/dental-implant/basal-dental-implant/comparative/Immediate'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              },
              {
                path: 'sans-os',
                name: 'sans-os',
                component: () => import(/* webpackChunkName: "withoutbone" */'../views/dental-implant/basal-dental-implant/comparative/WithoutBone'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              },
              {
                path: 'osteoporose',
                name: 'osteoporose',
                component: () => import(/* webpackChunkName: "osteoporosis" */'../views/dental-implant/basal-dental-implant/comparative/Osteoporosis'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              },
              {
                path: 'tabac-fumeur',
                name: 'tabac-fumeur',
                component: () => import(/* webpackChunkName: "tabacco" */'../views/dental-implant/basal-dental-implant/comparative/Tabacco'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              },
              {
                path: 'diabete',
                name: 'diabete',
                component: () => import(/* webpackChunkName: "diabetes" */'../views/dental-implant/basal-dental-implant/comparative/Diabetes'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'fr'
                  next()
                }
              }
            ]
          }
        ]
      },
      {
        path: 'anesthesie-generale',
        name: 'anesthesie-generale',
        component: () => import(/* webpackChunkName: "generalanaesthesia" */'../views/dental-implant/general-anaesthesia/GeneralAnaesthesia'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      }
    ]
  },
  {
    path: '/dental-implant',
    component: Main,
    children: [
      {
        path: '',
        name: 'dental-implant',
        component: () => import(/* webpackChunkName: "dentalimplant" */ '../views/dental-implant/DentalImplant'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'traditional-implant',
        component: Main,
        children: [
          {
            path: '',
            name: 'traditional-implant',
            component: () => import(/* webpackChunkName: "traditionaldentalimplant" */ '../views/dental-implant/traditional-dental-implant/TraditionalDentalImplant'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'bone-graft',
            component: Main,
            children: [
              {
                path: '',
                name: 'bone-graft',
                component: () => import(/* webpackChunkName: "bonecraft" */ '../views/dental-implant/traditional-dental-implant/bone-craft/BoneCraft'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              },
              {
                path: 'risks',
                name: 'risks-trad',
                component: () => import(/* webpackChunkName: "bonecraftrisk" */ '../views/dental-implant/traditional-dental-implant/bone-craft/BoneCraftRisk'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              }
            ]
          },
          {
            path: 'risks-and-rejection',
            name: 'risks-and-rejection',
            component: () => import(/* webpackChunkName: "traditionalimplantrisk" */ '../views/dental-implant/traditional-dental-implant/risk/TraditionalImplantRisk'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'all-on-4',
            name: 'all-on-4-en',
            component: () => import(/* webpackChunkName: "allonfour" */ '../views/dental-implant/traditional-dental-implant/all-on-4/AllOnFour'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'op',
            name: 'op-trad',
            component: () => import(/* webpackChunkName: "traditionalimplantoperation" */'../views/dental-implant/traditional-dental-implant/operation/TraditionalImplantOperation'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ]
      },
      {
        path: 'basal-implants',
        component: Main,
        children: [
          {
            path: '',
            name: 'basal-implants',
            component: () => import(/* webpackChunkName: "basaldentalimplant" */'../views/dental-implant/basal-dental-implant/BasalDentalImplant'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'fixed-dentures',
            name: 'fixed-dentures',
            component: () => import(/* webpackChunkName: "prosthesis" */'../views/dental-implant/basal-dental-implant/prothesis/Prosthesis'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'surgery',
            name: 'surgery',
            component: () => import(/* webpackChunkName: "basalimplantoperation" */'../views/dental-implant/basal-dental-implant/operation/BasalImplantOperation'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'risks',
            name: 'risks-basal',
            component: () => import(/* webpackChunkName: "basalimplantrisk" */'../views/dental-implant/basal-dental-implant/risk/BasalImplantRisk'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'comparison',
            component: Main,
            children: [
              {
                path: '',
                name: 'comparison',
                component: () => import(/* webpackChunkName: "comparative" */'../views/dental-implant/basal-dental-implant/comparative/Comparative'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              },
              {
                path: 'avoid-rejection',
                name: 'avoid-rejection',
                component: () => import(/* webpackChunkName: "reject" */'../views/dental-implant/basal-dental-implant/comparative/Reject'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              },
              {
                path: 'immediate-loading',
                name: 'immediate-loading',
                component: () => import(/* webpackChunkName: "immediate" */'../views/dental-implant/basal-dental-implant/comparative/Immediate'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              },
              {
                path: 'bone-loss',
                name: 'bone-loss',
                component: () => import(/* webpackChunkName: "withoutbone" */'../views/dental-implant/basal-dental-implant/comparative/WithoutBone'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              },
              {
                path: 'osteoporosis',
                name: 'osteoporosis',
                component: () => import(/* webpackChunkName: "osteoporosis" */'../views/dental-implant/basal-dental-implant/comparative/Osteoporosis'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              },
              {
                path: 'smoking',
                name: 'smoking',
                component: () => import(/* webpackChunkName: "tabacco" */'../views/dental-implant/basal-dental-implant/comparative/Tabacco'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              },
              {
                path: 'diabetes',
                name: 'diabetes',
                component: () => import(/* webpackChunkName: "diabetes" */'../views/dental-implant/basal-dental-implant/comparative/Diabetes'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'en'
                  next()
                }
              }
            ]
          }
        ]
      },
      {
        path: 'general-anesthesia',
        name: 'general-anesthesia',
        component: () => import(/* webpackChunkName: "generalanaesthesia" */'../views/dental-implant/general-anaesthesia/GeneralAnaesthesia'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      }
    ]
  },
  {
    path: '/ru/dental-implant',
    component: Main,
    children: [
      {
        path: '',
        name: 'ru-dental-implant',
        component: () => import(/* webpackChunkName: "dentalimplant" */ '../views/dental-implant/DentalImplant'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'traditional-implant',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-traditional-implant',
            component: () => import(/* webpackChunkName: "traditionaldentalimplant" */ '../views/dental-implant/traditional-dental-implant/TraditionalDentalImplant'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'bone-graft',
            component: Main,
            children: [
              {
                path: '',
                name: 'ru-bone-graft',
                component: () => import(/* webpackChunkName: "bonecraft" */ '../views/dental-implant/traditional-dental-implant/bone-craft/BoneCraft'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              },
              {
                path: 'risks',
                name: 'ru-risks-trad',
                component: () => import(/* webpackChunkName: "bonecraftrisk" */ '../views/dental-implant/traditional-dental-implant/bone-craft/BoneCraftRisk'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              }
            ]
          },
          {
            path: 'risks-and-rejection',
            name: 'ru-risks-and-rejection',
            component: () => import(/* webpackChunkName: "traditionalimplantrisk" */ '../views/dental-implant/traditional-dental-implant/risk/TraditionalImplantRisk'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'all-on-4',
            name: 'ru-all-on-4-en',
            component: () => import(/* webpackChunkName: "allonfour" */ '../views/dental-implant/traditional-dental-implant/all-on-4/AllOnFour'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'op',
            name: 'ru-op-trad',
            component: () => import(/* webpackChunkName: "traditionalimplantoperation" */'../views/dental-implant/traditional-dental-implant/operation/TraditionalImplantOperation'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ]
      },
      {
        path: 'basal-implants',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-basal-implants',
            component: () => import(/* webpackChunkName: "basaldentalimplant" */'../views/dental-implant/basal-dental-implant/BasalDentalImplant'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'fixed-dentures',
            name: 'ru-fixed-dentures',
            component: () => import(/* webpackChunkName: "prosthesis" */'../views/dental-implant/basal-dental-implant/prothesis/Prosthesis'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'surgery',
            name: 'ru-surgery',
            component: () => import(/* webpackChunkName: "basalimplantoperation" */'../views/dental-implant/basal-dental-implant/operation/BasalImplantOperation'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'risks',
            name: 'ru-risks-basal',
            component: () => import(/* webpackChunkName: "basalimplantrisk" */'../views/dental-implant/basal-dental-implant/risk/BasalImplantRisk'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'comparison',
            component: Main,
            children: [
              {
                path: '',
                name: 'ru-comparison',
                component: () => import(/* webpackChunkName: "comparative" */'../views/dental-implant/basal-dental-implant/comparative/Comparative'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              },
              {
                path: 'avoid-rejection',
                name: 'ru-avoid-rejection',
                component: () => import(/* webpackChunkName: "reject" */'../views/dental-implant/basal-dental-implant/comparative/Reject'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              },
              {
                path: 'immediate-loading',
                name: 'ru-immediate-loading',
                component: () => import(/* webpackChunkName: "immediate" */'../views/dental-implant/basal-dental-implant/comparative/Immediate'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              },
              {
                path: 'bone-loss',
                name: 'ru-bone-loss',
                component: () => import(/* webpackChunkName: "withoutbone" */'../views/dental-implant/basal-dental-implant/comparative/WithoutBone'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              },
              {
                path: 'osteoporosis',
                name: 'ru-osteoporosis',
                component: () => import(/* webpackChunkName: "osteoporosis" */'../views/dental-implant/basal-dental-implant/comparative/Osteoporosis'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              },
              {
                path: 'smoking',
                name: 'ru-smoking',
                component: () => import(/* webpackChunkName: "tabacco" */'../views/dental-implant/basal-dental-implant/comparative/Tabacco'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              },
              {
                path: 'diabetes',
                name: 'ru-diabetes',
                component: () => import(/* webpackChunkName: "diabetes" */'../views/dental-implant/basal-dental-implant/comparative/Diabetes'),
                beforeEnter: (to, from, next) => {
                  i18n.locale = 'ru'
                  next()
                }
              }
            ]
          }
        ]
      },
      {
        path: 'general-anesthesia',
        name: 'ru-general-anesthesia',
        component: () => import(/* webpackChunkName: "generalanaesthesia" */'../views/dental-implant/general-anaesthesia/GeneralAnaesthesia'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      }
    ]
  },
  {
    path: '/soins-dentaires',
    component: Main,
    children: [
      {
        path: '',
        name: 'soins-dentaires',
        component: () => import(/* webpackChunkName: "dentalcare" */'../views/dental-care/DentalCare'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'couronne-dentaire',
        name: 'couronne-dentaire',
        component: () => import(/* webpackChunkName: "dentalcrown" */'../views/dental-care/dental-crown/DentalCrown'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'bridge-dentaire',
        name: 'bridge-dentaire',
        component: () => import(/* webpackChunkName: "dentalbridge" */'../views/dental-care/dental-bridge/DentalBridge'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'facette-dentaire',
        name: 'facette-dentaire',
        component: () => import(/* webpackChunkName: "dentalveneer" */'../views/dental-care/dental-veneer/DentalVeneer'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'carie',
        name: 'carie',
        component: () => import(/* webpackChunkName: "dentaldecay" */'../views/dental-care/dental-decay/DentalDecay'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'blanchiment',
        name: 'blanchiment',
        component: () => import(/* webpackChunkName: "dentalwhitening" */'../views/dental-care/dental-whitening/DentalWhitening'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'orthodontie',
        name: 'orthodontie',
        component: () => import(/* webpackChunkName: "orthodontics" */'../views/dental-care/orthodontics/Orthodontics'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'decontamination-plombages-metaux-lourds',
        name: 'decontamination-plombages-metaux-lourds',
        component: () => import(/* webpackChunkName: "dentaldecontamination" */'../views/dental-care/dental-decontamination/DentalDecontamination'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'prothese-dentaire-stellite',
        name: 'prothese-dentaire-stellite',
        component: () => import(/* webpackChunkName: "dentalprosthesis" */'../views/dental-care/dental-prosthesis/DentalProsthesis'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
    ]
  },
  {
    path: '/dental-care',
    component: Main,
    children: [
      {
        path: '',
        name: 'dental-care',
        component: () => import(/* webpackChunkName: "dentalcare" */'../views/dental-care/DentalCare'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-crowns',
        name: 'dental-crowns',
        component: () => import(/* webpackChunkName: "dentalcrown" */'../views/dental-care/dental-crown/DentalCrown'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'bridges',
        name: 'bridges',
        component: () => import(/* webpackChunkName: "dentalbridge" */'../views/dental-care/dental-bridge/DentalBridge'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'veneers',
        name: 'veneers',
        component: () => import(/* webpackChunkName: "dentalveneer" */'../views/dental-care/dental-veneer/DentalVeneer'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'caries',
        name: 'caries',
        component: () => import(/* webpackChunkName: "dentaldecay" */'../views/dental-care/dental-decay/DentalDecay'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'whitening',
        name: 'whitening',
        component: () => import(/* webpackChunkName: "dentalwhitening" */'../views/dental-care/dental-whitening/DentalWhitening'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'orthodontics',
        name: 'orthodontics',
        component: () => import(/* webpackChunkName: "orthodontics" */'../views/dental-care/orthodontics/Orthodontics'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'decontamination-fillings-heavy-metals',
        name: 'decontamination-fillings-heavy-metals',
        component: () => import(/* webpackChunkName: "dentaldecontamination" */'../views/dental-care/dental-decontamination/DentalDecontamination'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'stellite-dental-dentures',
        name: 'stellite-dental-dentures',
        component: () => import(/* webpackChunkName: "dentalprosthesis" */'../views/dental-care/dental-prosthesis/DentalProsthesis'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
    ]
  },
  {
    path: '/ru/dental-care',
    component: Main,
    children: [
      {
        path: '',
        name: 'ru-dental-care',
        component: () => import(/* webpackChunkName: "dentalcare" */'../views/dental-care/DentalCare'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-crowns',
        name: 'ru-dental-crowns',
        component: () => import(/* webpackChunkName: "dentalcrown" */'../views/dental-care/dental-crown/DentalCrown'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'bridges',
        name: 'ru-bridges',
        component: () => import(/* webpackChunkName: "dentalbridge" */'../views/dental-care/dental-bridge/DentalBridge'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'veneers',
        name: 'ru-veneers',
        component: () => import(/* webpackChunkName: "dentalveneer" */'../views/dental-care/dental-veneer/DentalVeneer'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'caries',
        name: 'ru-caries',
        component: () => import(/* webpackChunkName: "dentaldecay" */'../views/dental-care/dental-decay/DentalDecay'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'whitening',
        name: 'ru-whitening',
        component: () => import(/* webpackChunkName: "dentalwhitening" */'../views/dental-care/dental-whitening/DentalWhitening'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'orthodontics',
        name: 'ru-orthodontics',
        component: () => import(/* webpackChunkName: "orthodontics" */'../views/dental-care/orthodontics/Orthodontics'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'decontamination-fillings-heavy-metals',
        name: 'ru-decontamination-fillings-heavy-metals',
        component: () => import(/* webpackChunkName: "dentaldecontamination" */'../views/dental-care/dental-decontamination/DentalDecontamination'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'stellite-dental-dentures',
        name: 'ru-stellite-dental-dentures',
        component: () => import(/* webpackChunkName: "dentalprosthesis" */'../views/dental-care/dental-prosthesis/DentalProsthesis'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
    ]
  },
  {
    path: '/cliniques-dentaires',
    component: Main,
    children: [
      {
        path: '',
        name: 'cliniques-dentaires',
        component: () => import(/* webpackChunkName: "clinic" */'../views/clinic/Clinic'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'centre-dentaire-suisse',
        component: Main,
        children: [
          {
            path: '',
            name: 'centre-dentaire-suisse',
            component: () => import(/* webpackChunkName: "swissdentalcenter" */'../views/clinic/swiss-dental-center/SwissDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          }
        ]
      },
      {
        path: 'clinique-dentaire-sofia-bulgarie',
        component: Main,
        children: [
          {
            path: '',
            name: 'clinique-dentaire-sofia-bulgarie',
            component: () => import(/* webpackChunkName: "sofiadentalcenter" */'../views/clinic/sofia-dental-center/SofiaDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          }
        ]
      },
      {
        path: 'cabinet-dentaire-belgrade-serbie',
        component: Main,
        children: [
          {
            path: '',
            name: 'cabinet-dentaire-belgrade-serbie',
            component: () => import(/* webpackChunkName: "serbiadentalcenter" */'../views/clinic/serbia-dental-center/SerbiaDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          }
        ]
      },
      {
        path: 'clinique-dentaire-montenegro',
        component: Main,
        children: [
          {
            path: '',
            name: 'clinique-dentaire-montenegro',
            component: () => import(/* webpackChunkName: "montenegrodentalcenter" */'../views/clinic/montenegro-dental-center/MontenegroDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          }
        ]
      },
      {
        path: 'laboratoire-prothese-dentaire',
        component: Main,
        children: [
          {
            path: '',
            name: 'laboratoire-prothese-dentaire',
            component: () => import(/* webpackChunkName: "laboratory" */'../views/clinic/laboratory/Laboratory'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          }
        ],
      },
      {
        path: 'equipes',
        component: Main,
        children: [
          {
            path: '',
            name: 'equipes',
            component: () => import(/* webpackChunkName: "team" */'../views/clinic/team/Team'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          }
        ]
      }
    ]
  },
  {
    path: '/dental-clinics',
    component: Main,
    children: [
      {
        path: '',
        name: 'dental-clinics',
        component: () => import(/* webpackChunkName: "clinic" */'../views/clinic/Clinic'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-center-switzerland',
        component: Main,
        children: [
          {
            path: '',
            name: 'dental-center-switzerland',
            component: () => import(/* webpackChunkName: "swissdentalcenter" */'../views/clinic/swiss-dental-center/SwissDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-clinic-bulgaria',
        component: Main,
        children: [
          {
            path: '',
            name: 'dental-clinic-bulgaria',
            component: () => import(/* webpackChunkName: "sofiadentalcenter" */'../views/clinic/sofia-dental-center/SofiaDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-clinic-serbia',
        component: Main,
        children: [
          {
            path: '',
            name: 'dental-clinic-serbia',
            component: () => import(/* webpackChunkName: "serbiadentalcenter" */'../views/clinic/serbia-dental-center/SerbiaDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-clinic-montenegro',
        component: Main,
        children: [
          {
            path: '',
            name: 'dental-clinic-montenegro',
            component: () => import(/* webpackChunkName: "montenegrodentalcenter" */'../views/clinic/montenegro-dental-center/MontenegroDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-laboratory',
        component: Main,
        children: [
          {
            path: '',
            name: 'dental-laboratory',
            component: () => import(/* webpackChunkName: "laboratory" */'../views/clinic/laboratory/Laboratory'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ],
      },
      {
        path: 'team',
        component: Main,
        children: [
          {
            path: '',
            name: 'team',
            component: () => import(/* webpackChunkName: "team" */'../views/clinic/team/Team'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ]
      }
    ]
  },
  {
    path: '/ru/dental-clinics',
    component: Main,
    children: [
      {
        path: '',
        name: 'ru-dental-clinics',
        component: () => import(/* webpackChunkName: "clinic" */'../views/clinic/Clinic'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-center-switzerland',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-dental-center-switzerland',
            component: () => import(/* webpackChunkName: "swissdentalcenter" */'../views/clinic/swiss-dental-center/SwissDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-clinic-bulgaria',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-dental-clinic-bulgaria',
            component: () => import(/* webpackChunkName: "sofiadentalcenter" */'../views/clinic/sofia-dental-center/SofiaDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-clinic-serbia',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-dental-clinic-serbia',
            component: () => import(/* webpackChunkName: "serbiadentalcenter" */'../views/clinic/serbia-dental-center/SerbiaDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-clinic-montenegro',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-dental-clinic-montenegro',
            component: () => import(/* webpackChunkName: "montenegrodentalcenter" */'../views/clinic/montenegro-dental-center/MontenegroDentalCenter'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ]
      },
      {
        path: 'dental-laboratory',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-dental-laboratory',
            component: () => import(/* webpackChunkName: "laboratory" */'../views/clinic/laboratory/Laboratory'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ],
      },
      {
        path: 'team',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-team',
            component: () => import(/* webpackChunkName: "team" */'../views/clinic/team/Team'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ]
      }
    ]
  },
  {
    path: '/pratique',
    component: Main,
    children: [
      {
        path: '',
        name: 'pratique',
        component: () => import(/* webpackChunkName: "useful" */'../views/useful/Useful'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'devis-dentaire',
        name: 'devis-dentaire',
        component: () => import(/* webpackChunkName: "dentalquote" */'../views/useful/dental-quote/DentalQuote'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'remboursement-soins-dentaires',
        name: 'remboursement-soins-dentaires',
        component: () => import(/* webpackChunkName: "dentalcarerefund" */'../views/useful/dental-care-refund/DentalCareRefund'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'soins-dentaires',
        component: Main,
        children: [
          {
            path: '',
            name: 'soins-dentaires-pratique',
            component: () => import(/* webpackChunkName: "usefuldentaltourism" */'../views/useful/useful-dental-care/UsefulDentalTourism'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'suisse',
            name: 'suisse',
            component: () => import(/* webpackChunkName: "usefuldentalcareswiss" */'../views/useful/useful-dental-care/usefulDentalCareSwiss'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'sofia-bulgarie',
            name: 'sofia-bulgarie',
            component: () => import(/* webpackChunkName: "usefuldentalcaresofia" */'../views/useful/useful-dental-care/usefulDentalCareSofia'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'belgrade-serbie',
            name: 'belgrade-serbie',
            component: () => import(/* webpackChunkName: "usefuldentalcareserbia" */'../views/useful/useful-dental-care/usefulDentalCareSerbia'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
          {
            path: 'montenegro',
            name: 'montenegro',
            component: () => import(/* webpackChunkName: "usefuldentalcaremontenegro" */'../views/useful/useful-dental-care/usefulDentalCareMontenegro'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'fr'
              next()
            }
          },
        ]
      }
    ]
  },
  {
    path: '/practice',
    component: Main,
    children: [
      {
        path: '',
        name: 'practice',
        component: () => import(/* webpackChunkName: "useful" */'../views/useful/Useful'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-quote',
        name: 'dental-quote',
        component: () => import(/* webpackChunkName: "dentalquote" */'../views/useful/dental-quote/DentalQuote'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'reimbursement',
        name: 'reimbursement',
        component: () => import(/* webpackChunkName: "dentalcarerefund" */'../views/useful/dental-care-refund/DentalCareRefund'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-care',
        component: Main,
        children: [
          {
            path: '',
            name: 'dental-care-practice',
            component: () => import(/* webpackChunkName: "usefuldentaltourism" */'../views/useful/useful-dental-care/UsefulDentalTourism'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'zurich-switzerland',
            name: 'zurich-switzerland',
            component: () => import(/* webpackChunkName: "usefuldentalcareswiss" */'../views/useful/useful-dental-care/usefulDentalCareSwiss'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'sofia-bulgaria',
            name: 'sofia-bulgaria',
            component: () => import(/* webpackChunkName: "usefuldentalcaresofia" */'../views/useful/useful-dental-care/usefulDentalCareSofia'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'belgrade-serbia',
            name: 'belgrade-serbia',
            component: () => import(/* webpackChunkName: "usefuldentalcareserbia" */'../views/useful/useful-dental-care/usefulDentalCareSerbia'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          },
          {
            path: 'san-stefan-montenegro',
            name: 'san-stefan-montenegro',
            component: () => import(/* webpackChunkName: "usefuldentalcaremontenegro" */'../views/useful/useful-dental-care/usefulDentalCareMontenegro'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'en'
              next()
            }
          }
        ]
      }
    ]
  },
  {
    path: '/ru/practice',
    component: Main,
    children: [
      {
        path: '',
        name: 'ru-practice',
        component: () => import(/* webpackChunkName: "useful" */'../views/useful/Useful'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-quote',
        name: 'ru-dental-quote',
        component: () => import(/* webpackChunkName: "dentalquote" */'../views/useful/dental-quote/DentalQuote'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'reimbursement',
        name: 'ru-reimbursement',
        component: () => import(/* webpackChunkName: "dentalcarerefund" */'../views/useful/dental-care-refund/DentalCareRefund'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-care',
        component: Main,
        children: [
          {
            path: '',
            name: 'ru-dental-care-practice',
            component: () => import(/* webpackChunkName: "usefuldentaltourism" */'../views/useful/useful-dental-care/UsefulDentalTourism'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'zurich-switzerland',
            name: 'ru-zurich-switzerland',
            component: () => import(/* webpackChunkName: "usefuldentalcareswiss" */'../views/useful/useful-dental-care/usefulDentalCareSwiss'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'sofia-bulgaria',
            name: 'ru-sofia-bulgaria',
            component: () => import(/* webpackChunkName: "usefuldentalcaresofia" */'../views/useful/useful-dental-care/usefulDentalCareSofia'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'belgrade-serbia',
            name: 'ru-belgrade-serbia',
            component: () => import(/* webpackChunkName: "usefuldentalcareserbia" */'../views/useful/useful-dental-care/usefulDentalCareSerbia'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          },
          {
            path: 'san-stefan-montenegro',
            name: 'ru-san-stefan-montenegro',
            component: () => import(/* webpackChunkName: "usefuldentalcaremontenegro" */'../views/useful/useful-dental-care/usefulDentalCareMontenegro'),
            beforeEnter: (to, from, next) => {
              i18n.locale = 'ru'
              next()
            }
          }
        ]
      }
    ]
  },
  {
    path: '/resultats',
    component: Main,
    children: [
      {
        path: '',
        name: 'resultats',
        component: () => import(/* webpackChunkName: "testimonial" */'../views/testimonial/Testimonial'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'traitement-chirurgical-parodontite',
        name: 'traitement-chirurgical-parodontite',
        component: () => import(/* webpackChunkName: "testimonial61" */'../views/testimonial/Testimonial61'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-dentaire-sans-greffe-osseuse',
        name: 'implant-dentaire-sans-greffe-osseuse',
        component: () => import(/* webpackChunkName: "testimonial62" */'../views/testimonial/Testimonial62'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implants-dentaires-immediate',
        name: 'implants-dentaires-immediate',
        component: () => import(/* webpackChunkName: "testimonial63" */'../views/testimonial/Testimonial63'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'gencives-qui-se-retractent',
        name: 'gencives-qui-se-retractent',
        component: () => import(/* webpackChunkName: "testimonial64" */'../views/testimonial/Testimonial64'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'retractation-gencive-parodontite',
        name: 'retractation-gencive-parodontite',
        component: () => import(/* webpackChunkName: "testimonial65" */'../views/testimonial/Testimonial65'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-dentaire-bouche-complete',
        name: 'implant-dentaire-bouche-complete',
        component: () => import(/* webpackChunkName: "testimonial66" */'../views/testimonial/Testimonial66'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'prothese-dentaire-fixe-implants-basals',
        name: 'prothese-dentaire-fixe-implants-basals',
        component: () => import(/* webpackChunkName: "testimonial67" */'../views/testimonial/Testimonial67'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-basal-biomed',
        name: 'implant-basal-biomed',
        component: () => import(/* webpackChunkName: "testimonial68" */'../views/testimonial/Testimonial68'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'technique-basal-perte-osseuse-parodontite',
        name: 'technique-basal-perte-osseuse-parodontite',
        component: () => import(/* webpackChunkName: "testimonial69" */'../views/testimonial/Testimonial69'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implants-greffe-os-sinus-lift',
        name: 'implants-greffe-os-sinus-lift',
        component: () => import(/* webpackChunkName: "testimonial610" */'../views/testimonial/Testimonial610'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-basal-sofia-bulgarie',
        name: 'implant-basal-sofia-bulgarie',
        component: () => import(/* webpackChunkName: "testimonial611" */'../views/testimonial/Testimonial611'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-dentaire-sans-os',
        name: 'implant-dentaire-sans-os',
        component: () => import(/* webpackChunkName: "testimonial612" */'../views/testimonial/Testimonial612'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      }
    ]
  },
  {
    path: '/results',
    component: Main,
    children: [
      {
        path: '',
        name: 'results',
        component: () => import(/* webpackChunkName: "testimonial" */'../views/testimonial/Testimonial'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'surgical-treatment-periodontitis',
        name: 'surgical-treatment-periodontitis',
        component: () => import(/* webpackChunkName: "testimonial61" */'../views/testimonial/Testimonial61'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-implant-without-bone-graft',
        name: 'dental-implant-without-bone-graft',
        component: () => import(/* webpackChunkName: "testimonial62" */'../views/testimonial/Testimonial62'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-implants-immediate',
        name: 'dental-implants-immediate',
        component: () => import(/* webpackChunkName: "testimonial63" */'../views/testimonial/Testimonial63'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'retractation-gum-periodontitis',
        name: 'retractation-gum-periodontitis',
        component: () => import(/* webpackChunkName: "testimonial64" */'../views/testimonial/Testimonial64'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'gum-retraction',
        name: 'gum-retraction',
        component: () => import(/* webpackChunkName: "testimonial65" */'../views/testimonial/Testimonial65'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-implant-complete-mouth',
        name: 'dental-implant-complete-mouth',
        component: () => import(/* webpackChunkName: "testimonial66" */'../views/testimonial/Testimonial66'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'fixed-dental-prosthesis-implants-basal',
        name: 'fixed-dental-prosthesis-implants-basal',
        component: () => import(/* webpackChunkName: "testimonial67" */'../views/testimonial/Testimonial67'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'biomed-implant-basal',
        name: 'biomed-implant-basal',
        component: () => import(/* webpackChunkName: "testimonial68" */'../views/testimonial/Testimonial68'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'basal-technique-bone-loss-periodontitis',
        name: 'basal-technique-bone-loss-periodontitis',
        component: () => import(/* webpackChunkName: "testimonial69" */'../views/testimonial/Testimonial69'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-implant-graft-bone-sinus-lift',
        name: 'dental-implant-graft-bone-sinus-lift',
        component: () => import(/* webpackChunkName: "testimonial610" */'../views/testimonial/Testimonial610'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'implant-basal-sofia-bulgaria',
        name: 'implant-basal-sofia-bulgaria',
        component: () => import(/* webpackChunkName: "testimonial611" */'../views/testimonial/Testimonial611'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-implant-without-bone',
        name: 'dental-implant-without-bone',
        component: () => import(/* webpackChunkName: "testimonial612" */'../views/testimonial/Testimonial612'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      }
    ]
  },
  {
    path: '/ru/results',
    component: Main,
    children: [
      {
        path: '',
        name: 'ru-results',
        component: () => import(/* webpackChunkName: "testimonial" */'../views/testimonial/Testimonial'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'surgical-treatment-periodontitis',
        name: 'ru-surgical-treatment-periodontitis',
        component: () => import(/* webpackChunkName: "testimonial61" */'../views/testimonial/Testimonial61'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-implant-without-bone-graft',
        name: 'ru-dental-implant-without-bone-graft',
        component: () => import(/* webpackChunkName: "testimonial62" */'../views/testimonial/Testimonial62'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-implants-immediate',
        name: 'ru-dental-implants-immediate',
        component: () => import(/* webpackChunkName: "testimonial63" */'../views/testimonial/Testimonial63'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'retractation-gum-periodontitis',
        name: 'ru-retractation-gum-periodontitis',
        component: () => import(/* webpackChunkName: "testimonial64" */'../views/testimonial/Testimonial64'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'gum-retraction',
        name: 'ru-gum-retraction',
        component: () => import(/* webpackChunkName: "testimonial65" */'../views/testimonial/Testimonial65'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-implant-complete-mouth',
        name: 'ru-dental-implant-complete-mouth',
        component: () => import(/* webpackChunkName: "testimonial66" */'../views/testimonial/Testimonial66'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'fixed-dental-prosthesis-implants-basal',
        name: 'ru-fixed-dental-prosthesis-implants-basal',
        component: () => import(/* webpackChunkName: "testimonial67" */'../views/testimonial/Testimonial67'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'biomed-implant-basal',
        name: 'ru-biomed-implant-basal',
        component: () => import(/* webpackChunkName: "testimonial68" */'../views/testimonial/Testimonial68'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'basal-technique-bone-loss-periodontitis',
        name: 'ru-basal-technique-bone-loss-periodontitis',
        component: () => import(/* webpackChunkName: "testimonial69" */'../views/testimonial/Testimonial69'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-implant-graft-bone-sinus-lift',
        name: 'ru-dental-implant-graft-bone-sinus-lift',
        component: () => import(/* webpackChunkName: "testimonial610" */'../views/testimonial/Testimonial610'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'implant-basal-sofia-bulgaria',
        name: 'ru-implant-basal-sofia-bulgaria',
        component: () => import(/* webpackChunkName: "testimonial611" */'../views/testimonial/Testimonial611'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-implant-without-bone',
        name: 'ru-dental-implant-without-bone',
        component: () => import(/* webpackChunkName: "testimonial612" */'../views/testimonial/Testimonial612'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      }
    ]
  },
  {
    path: '/articles',
    component: Main,
    children: [
      {
        path: '',
        name: 'articles',
        component: () => import(/* webpackChunkName: "testimonial" */'../views/article/Article'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'constitution-os-machoire',
        name: 'constitution-os-machoire',
        component: () => import(/* webpackChunkName: "article81" */'../views/article/Article81'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'diabete-implant-dentaire',
        name: 'diabete-implant-dentaire',
        component: () => import(/* webpackChunkName: "article82" */'../views/article/Article82'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'fabrication-prothese-dentaire',
        name: 'fabrication-prothese-dentaire',
        component: () => import(/* webpackChunkName: "article83" */'../views/article/Article83'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-basal-france',
        name: 'implant-basal-france',
        component: () => import(/* webpackChunkName: "article84" */'../views/article/Article84'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'infections-bucco-dentaires',
        name: 'infections-bucco-dentaires',
        component: () => import(/* webpackChunkName: "article85" */'../views/article/Article85'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'parodontite',
        name: 'parodontite',
        component: () => import(/* webpackChunkName: "article86" */'../views/article/Article86'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'probleme-dentaire-femme-enceinte',
        name: 'probleme-dentaire-femme-enceinte',
        component: () => import(/* webpackChunkName: "article87" */'../views/article/Article87'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'implant-dentaire-periimplantite',
        name: 'implant-dentaire-periimplantite',
        component: () => import(/* webpackChunkName: "article88" */'../views/article/Article88'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'risques-cardiaque-infections-dentaires',
        name: 'risques-cardiaque-infections-dentaires',
        component: () => import(/* webpackChunkName: "article89" */'../views/article/Article89'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'devitalisation',
        name: 'devitalisation',
        component: () => import(/* webpackChunkName: "article810" */'../views/article/Article810'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      },
      {
        path: 'probleme-dentaire-risque-occulaire',
        name: 'probleme-dentaire-risque-occulaire',
        component: () => import(/* webpackChunkName: "article811" */'../views/article/Article811'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'fr'
          next()
        }
      }
    ]
  },
  {
    path: '/article',
    component: Main,
    children: [
      {
        path: '',
        name: 'article',
        component: () => import(/* webpackChunkName: "testimonial" */'../views/article/Article'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'jawbone-formation',
        name: 'jawbone-formation',
        component: () => import(/* webpackChunkName: "article81" */'../views/article/Article81'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'diabetes-and-dental-implants',
        name: 'diabetes-and-dental-implants',
        component: () => import(/* webpackChunkName: "article82" */'../views/article/Article82'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'manufacture-dental-prothesis',
        name: 'manufacture-dental-prothesis',
        component: () => import(/* webpackChunkName: "article83" */'../views/article/Article83'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'basal-implant-in-france',
        name: 'basal-implant-in-france',
        component: () => import(/* webpackChunkName: "article84" */'../views/article/Article84'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'oral-diseases',
        name: 'oral-diseases',
        component: () => import(/* webpackChunkName: "article85" */'../views/article/Article85'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'periodontitis',
        name: 'periodontitis',
        component: () => import(/* webpackChunkName: "article86" */'../views/article/Article86'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'dental-problems-in-pregnancy',
        name: 'dental-problems-in-pregnancy',
        component: () => import(/* webpackChunkName: "article87" */'../views/article/Article87'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'perimplantitis',
        name: 'perimplantitis',
        component: () => import(/* webpackChunkName: "article88" */'../views/article/Article88'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'cardiac-risk-and-dental-infections',
        name: 'cardiac-risk-and-dental-infections',
        component: () => import(/* webpackChunkName: "article89" */'../views/article/Article89'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'devitalization',
        name: 'devitalization',
        component: () => import(/* webpackChunkName: "article810" */'../views/article/Article810'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      },
      {
        path: 'eye-dental-infections',
        name: 'eye-dental-infections',
        component: () => import(/* webpackChunkName: "article811" */'../views/article/Article811'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'en'
          next()
        }
      }
    ]
  },
  {
    path: '/ru/article',
    component: Main,
    children: [
      {
        path: '',
        name: 'ru-article',
        component: () => import(/* webpackChunkName: "testimonial" */'../views/article/Article'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'jawbone-formation',
        name: 'ru-jawbone-formation',
        component: () => import(/* webpackChunkName: "article81" */'../views/article/Article81'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'diabetes-and-dental-implants',
        name: 'ru-diabetes-and-dental-implants',
        component: () => import(/* webpackChunkName: "article82" */'../views/article/Article82'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'manufacture-dental-prothesis',
        name: 'ru-manufacture-dental-prothesis',
        component: () => import(/* webpackChunkName: "article83" */'../views/article/Article83'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'basal-implant-in-france',
        name: 'ru-basal-implant-in-france',
        component: () => import(/* webpackChunkName: "article84" */'../views/article/Article84'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'oral-diseases',
        name: 'ru-oral-diseases',
        component: () => import(/* webpackChunkName: "article85" */'../views/article/Article85'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'periodontitis',
        name: 'ru-periodontitis',
        component: () => import(/* webpackChunkName: "article86" */'../views/article/Article86'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'dental-problems-in-pregnancy',
        name: 'ru-dental-problems-in-pregnancy',
        component: () => import(/* webpackChunkName: "article87" */'../views/article/Article87'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'perimplantitis',
        name: 'ru-perimplantitis',
        component: () => import(/* webpackChunkName: "article88" */'../views/article/Article88'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'cardiac-risk-and-dental-infections',
        name: 'ru-cardiac-risk-and-dental-infections',
        component: () => import(/* webpackChunkName: "article89" */'../views/article/Article89'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'devitalization',
        name: 'ru-devitalization',
        component: () => import(/* webpackChunkName: "article810" */'../views/article/Article810'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      },
      {
        path: 'eye-dental-infections',
        name: 'ru-eye-dental-infections',
        component: () => import(/* webpackChunkName: "article811" */'../views/article/Article811'),
        beforeEnter: (to, from, next) => {
          i18n.locale = 'ru'
          next()
        }
      }
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'fr'
      next()
    }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'en'
      next()
    }
  },
  {
    path: '/ru/contact-us',
    name: 'ru-contact-us',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'ru'
      next()
    }
  },
  {
    path: '/merci',
    name: 'merci',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'fr'
      next()
    }
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'en'
      next()
    }
  },
  {
    path: '/ru/thanks',
    name: 'ru-thanks',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'ru'
      next()
    }
  },
  {
    path: '/erreur',
    name: 'erreur',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'fr'
      next()
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'en'
      next()
    }
  },
  {
    path: '/ru/error',
    name: 'ru-error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    beforeEnter: (to, from, next) => {
      i18n.locale = 'ru'
      next()
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }

})

export default router
